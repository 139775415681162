import React, { lazy } from 'react';
import dashboard from '@modules/dashboard';
import basicInfo from '@modules/basicInfo';
import setting from '@modules/setting';
import businessAffairs from '@modules/businessAffairs';
import listManagement from '@modules/listManagement';
import recruitManagement from '@modules/recruitManagement';
import myCommission from '@modules/myCommission';
import supplier from '@modules/supplier';
import placeManagement from '@modules/placeManagement';
import complaintManagement from '@modules/complaintManagement';
import salarySlip from '@modules/salarySlip';
import financialManage from '@modules/financialManage';
import dormitoryManage from '@modules/dormitoryManage';
import contractMannage from '@modules/contractMannage';
import warnManage from '@/modules/warnManage';
import ycFinance from '@/modules/ycFinance';

const NotAuthorized = lazy(() => import('@modules/error/notAuthorized'));

export default [
  dashboard,
  setting,
  basicInfo,
  businessAffairs,
  supplier,
  placeManagement,
  recruitManagement,
  listManagement,
  myCommission,
  complaintManagement,
  salarySlip,
  financialManage,
  dormitoryManage,
  contractMannage,
  warnManage,
  ycFinance,
  {
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
];
